import axios from 'axios';

const token = localStorage.getItem('token');

const instance = axios.create({
  baseURL: `https://dev.onsocial.com.br`,
  headers: {
    Authorization: token ? String(`Bearer${token.replace(/"/g, '')}`) : ''
  }
});

export default instance;
