import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'start',
    padding: theme.spacing(3, 0, 2, 10),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(3, 0, 2, 6)
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 0, 2, 5)
    },
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  typography: {
    color: '#ffffff'
  }
}));

const Left = props => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Typography className={classes.typography} variant="body2">
        OnSocial Serviços de Tecnologia
      </Typography>
      <Typography className={classes.typography} variant="body2">
        Rua Ernesto de Paula Santos 187, Sala 1403
      </Typography>
      <Typography className={classes.typography} variant="body2">
        Boa Viagem, Recife - PE
      </Typography>
      <Typography className={classes.typography} variant="body2">
        Cep 51021-330
      </Typography>
      <Typography className={classes.typography} variant="body2">
        Tel: (81) 996.404.811
      </Typography>
      <Typography className={classes.typography} variant="body2">
        info@onsocial.com.br | www.onsocial.com.br
      </Typography>
    </Card>
  );
};

export default Left;
