import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
    alignItems: 'start',
    padding: theme.spacing(0, 10, 2, 0),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 10, 2, 0)
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 0, 2, 5)
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1, 2, 5),
      alignItems: 'start'
    },
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  typography: {
    color: '#ffffff'
  }
}));

const Right = () => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Typography className={classes.typography} variant="body2">
        Desenvolvido com amor por OnSocial em
      </Typography>
      <Typography className={classes.typography} variant="body2">
        Parceria com @comeialab
      </Typography>
    </Card>
  );
};

export default Right;
