/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import HomeIcon from '@material-ui/icons/HomeOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';

export default [
  {
    title: 'Home',
    pages: [
      {
        title: 'Dashboard',
        href: '/dashboards/default',
        icon: HomeIcon
      },
      {
        title: 'Perfil',
        href: '/profile',
        icon: PersonIcon,
        children: [
          {
            title: 'Meu perfil',
            href: '/profile/1'
          }
        ]
      }
    ]
  }
];
