import { makeStyles } from '@material-ui/styles';
import { Page, SearchBar } from 'components';
import React, { useEffect, useState } from 'react';
import axios from 'utils/axios';
import { Results } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(5)
  },
  results: {
    marginTop: theme.spacing(3)
  }
}));

const OrderManagementList = () => {
  const classes = useStyles();
  const [rows, setRows] = useState([
    {
      id: 1,
      fullName: 'vinicius',
      phone: '814546465465',
      type: { name: '', displayName: 'acidente' },
      created_at: '2020-02-12 23:50:55',
      local: 'ali na rua'
    }
  ]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState({});

  const handleRequestSuccess = response => {
    setRows(response.data.content);
    setTotalElements(response.data.totalElements);
    setTotalPages(response.data.totalPages);
    setIsError(false);
  };

  const handleRequestError = error => {
    setIsError(true);
    setError(error.response.data);
  };

  const handleRequestFinally = () => {
    setLoading(false);
  };

  const fetch = (order, orderBy) => {
    const sort = orderBy ? `${orderBy},${order}` : null;
    const params = {
      sort
    };

    setLoading(true);
    setIsError(false);
    setRows([]);
    setOrder(order);
    setOrderBy(orderBy);

    axios
      .get('', { params })
      .then(response => {
        handleRequestSuccess(response);
      })
      .catch(error => {
        handleRequestError(error);
      })
      .finally(() => {
        handleRequestFinally();
      });
  };

  useEffect(() => {
    let mounted = false;

    if (mounted) fetch(order, orderBy);

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Page className={classes.root}>
      <SearchBar />
      <Results
        className={classes.results}
        orders={rows} //
      />
    </Page>
  );
};

export default OrderManagementList;
