import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Hidden, Paper, Box } from '@material-ui/core';
import { Left, Center, Right } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    bottom: 0,
    width: '100%',
    minHeight: 130,
    backgroundColor: '#373737'
  }
}));

const Footer = props => {
  const { route } = props;

  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
        <Left />
      </Grid>
      <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
        <Center />
      </Grid>
      <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
        <Right />
      </Grid>
    </Grid>
  );
};

Footer.propTypes = {
  route: PropTypes.object
};

export default Footer;
