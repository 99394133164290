import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createBrowserHistory } from 'history';
import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { Router } from 'react-router-dom';
import './assets/scss/index.scss';
import { ScrollReset } from './components';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/prismjs';
import './mixins/validate';
import routes from './routes';
import { configureStore } from './store';
import theme from './theme';

const history = createBrowserHistory();
const store = configureStore();

const App = () => (
  <StoreProvider store={store}>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router history={history}>
          <ScrollReset />
          {renderRoutes(routes)}
        </Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </StoreProvider>
);

export default App;
