import {
  AppBar,
  Avatar,
  Badge,
  Button,
  ButtonGroup,
  colors,
  IconButton,
  InputBase,
  Paper,
  Toolbar,
  Typography
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import EmailIcon from '@material-ui/icons/Email';
import NotificationsIcon from '@material-ui/icons/Notifications';
import RoomIcon from '@material-ui/icons/Room';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useRouter from 'utils/useRouter';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow:
      '0 0.125rem 0.3rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 0.75rem -0.0625rem rgba(0, 0, 0, 0.06) !important',
    backgroundColor: '#000000',
    borderColor: '#3e82f7',
    height: 60
    // justifyContent: 'center'
  },
  root2: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 190,
    backgroundColor: '#dbdbdb',
    height: 35,
    borderRadius: 10,
    marginLeft: theme.spacing(1)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  content: {
    width: theme.breakpoints.values.md,
    maxWidth: '100%',
    margin: '0 auto'
  },
  flexGrow: {
    flexGrow: 0.3
  },
  search: {
    backgroundColor: 'rgba(50, 50, 50, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(1),
    color: '#888'
  },
  searchInput: {
    flexGrow: 1,
    // color: 'inherit',
    '& input::placeholder': {
      opacity: 1
      // color: 'inherit'
    }
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  },
  iconButton: {
    display: 'flex',
    flexDirection: 'column'
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  logo: {
    width: 110,
    borderRadius: 2,
    marginRight: 130,
    [theme.breakpoints.down('md')]: {
      marginRight: 5,
      width: 60
    }
  },
  btnEnter: {
    color: '#fff',
    marginLeft: 15,
    [theme.breakpoints.down('md')]: {
      marginLeft: 5
    }
  },
  btnGroup: {
    backgroundColor: '#dbdbdb',
    fontSize: 12,
    textTransform: 'capitalize',
    [theme.breakpoints.down('md')]: {
      fontSize: 9
    }
  },
  containerAvatarBtnEnter: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      '& $btnEnter': {
        color: '#dbdbdb'
      }
    }
  }
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const { history } = useRouter();

  const handleLink = link => {
    history.push(link);
  };

  const handleSubmit = event => {
    event.preventDefault();
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <div className={classes.flexGrow} />

        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/logo-onsocial-01.png"
            className={classes.logo}
          />
        </RouterLink>

        {/* <div className={classes.flexGrow} /> */}

        <ButtonGroup
          variant="contained"
          size="small"
          aria-label="small outlined button group"
        >
          <Button
            className={classes.btnGroup}
            style={{
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              backgroundColor: '#dfe5e9'
            }}
          >
            OnSocial
          </Button>
          <Button
            className={classes.btnGroup}
            style={{ backgroundColor: '#dfe5e9' }}
          >
            Buscar
          </Button>
          <Button
            className={classes.btnGroup}
            style={{ backgroundColor: '#dfe5e9' }}
          >
            Governo
          </Button>
          <Button
            className={classes.btnGroup}
            style={{ backgroundColor: '#dfe5e9' }}
          >
            Perfil
          </Button>
          <Button
            className={classes.btnGroup}
            style={{
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
              backgroundColor: '#dfe5e9'
            }}
          >
            Novo usuário
          </Button>
        </ButtonGroup>

        <IconButton
          style={{
            backgroundColor: '#dbdbdb',
            borderRadius: 8,
            height: 30,
            width: 35,
            marginLeft: 7
          }}
        >
          <Badge badgeContent={4} color="error">
            <EmailIcon fontSize="small" style={{ color: '#000' }} />
          </Badge>
        </IconButton>

        <IconButton
          style={{
            backgroundColor: '#dbdbdb',
            borderRadius: 8,
            height: 30,
            width: 35,
            marginLeft: 7
          }}
        >
          <Badge badgeContent={4} color="error">
            <NotificationsIcon fontSize="small" style={{ color: '#000' }} />
          </Badge>
        </IconButton>

        <IconButton
          style={{
            backgroundColor: '#dbdbdb',
            borderRadius: 8,
            height: 30,
            width: 35,
            marginLeft: 7
          }}
        >
          <CardTravelIcon fontSize="small" style={{ color: '#000' }} />
        </IconButton>

        <form onSubmit={handleSubmit}>
          <Paper component="form" className={classes.root2}>
            <InputBase
              className={classes.input}
              placeholder="País, estado ou cidade"
              inputProps={{ 'aria-label': 'País, estado ou cidade' }}
            />
            <IconButton
              size="small"
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <RoomIcon fontSize="small" style={{ color: '#000' }} />
            </IconButton>
          </Paper>
        </form>

        <div className={classes.flexGrow} />

        <div className={classes.containerAvatarBtnEnter}>
          <Avatar
            style={{
              backgroundColor: '#000',
              color: '#3dd4a7',
              height: 30,
              width: 30
            }}
          >
            {' '}
            <AccountCircleIcon />
          </Avatar>
          <Typography variant="body1" className={classes.btnEnter}>
            Entrar
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
