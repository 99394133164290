import { Avatar, Card, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.white,
    backgroundColor: '#30c59c',
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: '#30c59c',
    height: 48,
    width: 48
  }
}));

const RoiPerCustomer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const data = {
    value: '2550'
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <div>
        <Typography
          color="inherit"
          component="h3"
          gutterBottom
          variant="overline"
        >
          QTD. chamados atendidos
        </Typography>
        <div className={classes.details}>
          <Typography color="inherit" variant="h3">
            {data.value}
          </Typography>
        </div>
      </div>
      <Avatar className={classes.avatar} color="inherit">
        <CheckIcon />
      </Avatar>
    </Card>
  );
};

RoiPerCustomer.propTypes = {
  className: PropTypes.string
};

export default RoiPerCustomer;
