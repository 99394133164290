import React, { Fragment, Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { Topbar } from './components';
import { Footer } from './components/Footer';

const useStyles = makeStyles(theme => ({
  content: {
    height: '100vh',
    paddingTop: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    },
    boxShadow:
      '0 0.125rem 0.3rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 0.75rem -0.0625rem rgba(0, 0, 0, 0.06) !important',
    backgroundColor: '#000',
    borderColor: '#FFFF'
  }
}));

const Auth = props => {
  const { route } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <Topbar />
      <main className={classes.content}>
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(route.routes)}
        </Suspense>
        <Footer />
      </main>
    </Fragment>
  );
};

Auth.propTypes = {
  route: PropTypes.object
};

export default Auth;
