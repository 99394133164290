import { Avatar, Card, colors, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Label } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import gradients from 'utils/gradients';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  avatar: {
    backgroundImage: gradients.green,
    height: 48,
    width: 48
  }
}));

const TodaysMoney = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const data = {
    value: '240',
    currency: '$',
    difference: '+4.5%'
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <div>
        <Typography component="h3" gutterBottom variant="overline">
          QTD. de usuários
        </Typography>
        <div className={classes.details}>
          <Typography variant="h3">{data.value}</Typography>
          <Label
            className={classes.label}
            color={colors.green[600]}
            variant="outlined"
          >
            {data.difference}
          </Label>
        </div>
      </div>
      <Avatar className={classes.avatar}>
        <PersonIcon />
      </Avatar>
    </Card>
  );
};

TodaysMoney.propTypes = {
  className: PropTypes.string
};

export default TodaysMoney;
